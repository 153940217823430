<div class="header">
  <mat-toolbar class="mat-primary fixed">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <span id="logo" [routerLink]="['/controle-de-ponto']"></span>
      <span class="space"></span>
      <app-header></app-header>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav class="fixed fixed-sidenav ">
      <mat-nav-list>
        <div class="item" [routerLink]="['/controle-de-ponto']" routerLinkActive="item-active">
          <span class="material-icons">fingerprint</span>
          <span>Controle de Ponto</span>
        </div>
        <div class="item" [routerLink]="['/acessorios']" routerLinkActive="item-active">
          <span class="material-icons">dialpad</span>
          <span>Acessórios</span>
        </div>

        <div class="item" [routerLink]="['/equipamentos']" routerLinkActive="item-active">
          <span class="material-icons">edit</span>
          <span>Equipamentos</span>
        </div>

        <span class="item" [routerLink]="['/contato']" routerLinkActive="item-active">
          <span class="material-icons">call</span>
          <span>Contato</span>
        </span>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
