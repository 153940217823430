<!-- main-section -->
<div class="main-section">
  <div class="main-section-grid">
    <div class="title-column">
      <div class="title">
        Controle de Ponto fácil e seguro
      </div>
      <div class="subtitle">
        Tecnologia e eficiência
      </div>
    </div>
    <div class="img-column">
      <img src="./assets/img/reps.png" width="100%">
    </div>
  </div>
</div>

<div class="layout">
  <div class="esquerda"></div>
  <div class="direita"></div>
</div>

<!-- secundary-section -->
<div class="secundary-section">
  <div class="title-section">
    <div class="title-component">Relógio de Ponto Eletrônico</div>
    <div class="subtitle-component">Homologado pelo MTE e certificado pelo Inmetro.</div>
  </div>
  <div class="grid-section">
    <div class="components-column">
      <div class="component">
        <div class="img-component">
          <img src="./assets/img/digitalizacao-de-impressoes-digitais.svg">
        </div>
        <div class="title">Módulo Biometria</div>
        <div class="text-component">Diversos modelos homologados com diferentes
          combinações de leitores e formas de identificação
          do usuário como biometria
        </div>
      </div>
      <div class="component">
        <div class="img-component">
          <img src="./assets/img/001-cloud.svg">
        </div>
        <div class="title">Tecnologia com recursos e funcionalidades otimizadas</div>
        <div class="text-component">Ideal para todos os portes e segmentos de empresas
          que necessitam de uma solução de ponto segura
          e automatizada
        </div>
      </div>
      <div class="component">
        <div class="img-component">
          <img src="./assets/img/001-cloud.svg">
        </div>
        <div class="title">Software de controle de ponto</div>
        <div class="text-component">Automatiza a tarefa de registrar e calcular o cartão de
          ponto dos colaboradores, otimizando o tempo de gestores
          e profissionais de RH.
        </div>
      </div>
    </div>
    <div class="img-column">
      <img id="img-full-screen" src="./assets/img/prisma-corte@2x.png" width="90%">
      <img id="img-mobile-screen" src="./assets/img/prisma-mobile-screen.png" width="90%">
    </div>
  </div>
</div>

<!-- section-three -->
<div class="section-three">
  <div class="title-section">
    <div class="title-component">Precisa de ajuda?</div>
    <div class="subtitle-component">Temos suporte dedicado e recursos para ajuda-lo em caso de dúvidas</div>
  </div>
  <div class="support-section">
    <img class="img-one" src="./assets/img/fundo-support.svg">
  </div>
</div>

<!-- section-four -->
<div class="section-four">
  <div class="content-section-four">
    <div class="title-section">
      <div class="title-component">Parceiros</div>
    </div>

    <div class="client-img">
      <div class="client" *ngFor="let partners of partnerList">
        <img [src]="partners">
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
