import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/interface/product.interface';
import { ProductService } from 'src/app/services/product.service';

import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { style } from '@angular/animations';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  slides = [
    { image: 'https://bridezillaandco.com/img/slider/h4_slide_1.jpg' },
    { image: './assets/img/fita.png' },
    { image: 'https://gsr.dev/material2-carousel/assets/demo.png' },
    { image: 'https://gsr.dev/material2-carousel/assets/demo.png' },
    { image: 'https://gsr.dev/material2-carousel/assets/demo.png' },
  ];
  public product: Product;

  constructor(private productService: ProductService) {
    this.product = this.productService.selectedProduct;
  }

  ngOnInit(): void {
    // this.getproduct;
  }

  private getproduct(): void {
    this.productService
      .getProduct()
      .subscribe((product: Product) => (this.product = product));
  }
}
