import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from '../pages/home.routing';
import { MaterialModule } from '../material/material.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductsComponent } from './products/products.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProductsComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ProductsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HomeRoutingModule
  ]
})
export class ComponentsModule { }
