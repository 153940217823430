<div class="teste">
  <div class="layout">
    <div class="direita"></div>
    <div class="esquerda"></div>
  </div>

  <div class="footer">
    <div class="footer-content">
      <div class="title">Revenda Autorizada</div>
      <div class="img-item">
        <div class="img">
          <img id="henry" src="./assets/img/henry-autorizada.png">
        </div>
        <div class="img">
          <img id="secullum" src="./assets/img/secullum-autorizadaaa.png">
        </div>
      </div>
      <div class="text">2023 ROD-VALE - Venda e Assistência Técnica de Relógios de Ponto<br>
        CNPJ 30.548.674/0001-96</div>
    </div>
  </div>

  <div class="whatsapp-flutuante">
    <a href="https://api.whatsapp.com/send?1=pt_BR&phone=+551236326219&text=Bom%20dia!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento."
      target="_blank">
      <img class="whatsapp" src="./assets/img/whatsapp-icon.png">
    </a>
  </div>
</div>
