import { Component, OnInit } from '@angular/core';

import { PageData } from '../../interface/page-data.interface';
import { AcessoryService } from '../../services/acessory.service';
import { AccessoryList } from '../../interface/accessory-list.interface';

@Component({
  selector: 'app-accessory',
  templateUrl: './accessory.component.html',
  styleUrls: ['./accessory.component.scss']
})
export class AccessoryComponent implements OnInit {

  public pageData: PageData;
  public accessoryList: AccessoryList[];

  constructor(private accessoryService: AcessoryService) { }

  ngOnInit(): void {
    this.getAccessoryPageData();
    this.getAccessoryList();
  }

  private getAccessoryPageData(): void {
    this.accessoryService.getAccessoryPageData()
      .subscribe((pageData: PageData) => this.pageData = pageData);
  }

  private getAccessoryList(): void {
    this.accessoryService.getAccessoryList()
      .subscribe((equipmentList: AccessoryList[]) => this.accessoryList = equipmentList);
  }

  showSecondaryBackground(index: number): boolean {
    return index % 2 !== 0;
  }

}
