import { Component, Input } from '@angular/core';

import { Product } from '../../interface/product.interface';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

  @Input() public product: Product;


}
