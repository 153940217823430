import { Component, OnInit } from '@angular/core';
import { PartnerList } from 'src/app/interface/partner-list.interface';
import { PartnerService } from 'src/app/services/partner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public partnerList: PartnerList[];

  constructor(private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.getPartnerList();
  }

  private getPartnerList(): void {
    this.partnerService.getPartnerList()
      .subscribe((partnerList: PartnerList[]) => this.partnerList = partnerList )
  }

}
