<div class="product-details">

  <div class="product">
    <!-- <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="3000" color="warn" maxWidth="auto" proportion="45"
      slides="5" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="false" orientation="ltr">
      <mat-carousel-slide *ngFor="let slide of slides; let i = index" [image]="slide.image" [hideOverlay]="true">
      </mat-carousel-slide>
    </mat-carousel> -->

    <div class="img-area">
      <img [src]="product?.img">
    </div>

    <div class="description-area">
      <div class="title">{{product?.name}}</div>
      <div class="content">
        <p *ngFor="let text of product?.description">{{text}}</p>
        <div class="product-img">
          <img [src]="product?.featureImg">
        </div>
        <p>Confira as especificações técnicas clicando <a [href]="product?.link" target="_blank">AQUI.</a></p>
      </div>

    </div>
  </div>

  <app-footer></app-footer>
</div>

<!-- <div class="card">
    <div class="title">Formas de pagamento</div>
    <div class="subtitle">Cartão de crédito</div>
    <div class="img-list">
      <div class="img" id="Mastercard" title="Mastercard"></div>
      <div class="img" id="Visa" title="Visa"></div>
      <div class="img" id="Elo" title="Elo"></div>
      <div class="img" id="Hipercard" title="Hipercard"></div>
      <div class="img" id="Hiper" title="Hiper"></div>
      <div class="img" id="Cabal" title="Cabal"></div>
      <div class="img" id="American" title="American Express"></div>
      <div class="img" id="Diners" title="Diners Club"></div>
    </div>

    <div class="subtitle">Cartão de débito</div>
    <div class="img-list">
      <div class="img" id="Maestro" title="Maestro"></div>
      <div class="img" id="visa-electron" title="Visa Electron"></div>
      <div class="img" id="Elo" title="Elo"></div>
      <div class="img" id="Banri" title="Banri Compras"></div>
      <div class="img" id="cabal-debito" title="Cabal Débito"></div>
    </div>

    <div class="subtitle">Boleto Bancário</div>
    <div class="img-list">
      <div class="img" id="Boleto" title="Boleto Bancário"></div>
    </div>
    <div class="text">*com garantia de fábrica inclusa</div>

    <button mat-raised-button>Saiba Mais</button>
  </div> -->
