import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PageData } from '../interface/page-data.interface';
import { AccessoryList } from '../interface/accessory-list.interface';

@Injectable({
  providedIn: 'root',
})
export class AcessoryService {
  constructor(private http: HttpClient) {}

  getAccessoryPageData(): Observable<PageData> {
    return this.http.get<PageData>(
      '../../assets/json/AccessoryPageData.json'
    );
  }

  getAccessoryList(): Observable<AccessoryList[]> {
    return this.http.get<AccessoryList[]>(
      '../../assets/json/AccessoryList.json'
    );
  }
}
