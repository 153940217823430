<div class="header">
  <div class="item" [routerLink]="['/controle-de-ponto']" routerLinkActive="item-active">
    <span class="material-icons">fingerprint</span>
    <span>Controle de Ponto</span>
  </div>

  <span class="item" [routerLink]="['/acessorios']" routerLinkActive="item-active">
    <span class="material-icons">dialpad</span>
    <span>Acessórios</span>
  </span>

  <span class="item" [routerLink]="['/equipamentos']" routerLinkActive="item-active">
    <span class="material-icons">edit</span>
    <span>Equipamentos</span>
  </span>

  <span class="item" [routerLink]="['/contato']" routerLinkActive="item-active">
    <span class="material-icons">call</span>
    <span>Contato</span>
  </span>
</div>

