<div class="title-section colored-title">
  <div class="title">{{pageData?.title}}</div>
  <div class="subtitle">{{pageData?.description}}</div>
</div>

<div class="products-content" *ngFor="let equipments of equipmentList; let index = index"
  [class.equipment-background-list]="showSecondaryBackground(index)">
  <div class="title-component">{{equipments?.title}}</div>

  <div class="equipment-list" *ngFor="let product of equipments?.products" >
    <div class="equipment-item" (click)="openProduct(product)">
      <app-products [product]="product"></app-products>
    </div>
  </div>
</div>

<div class="skeleton-title skeleton">{{pageData?.title}}</div>
<div class="skeleton-grid">
  <div class="skeleton-img skeleton">{{pageData?.title}}</div>

  <div class="text-skeleton">
    <div class="skeleton-subtitle skeleton">{{pageData?.title}}</div>
    <div class="skeleton-text skeleton">{{pageData?.title}}</div>
  </div>
</div>


<app-footer></app-footer>
