import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PageData } from '../interface/page-data.interface';
import { EquipmentList } from '../interface/equipment-list.interface';
import { Product } from '../interface/product.interface';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {



  constructor(private http: HttpClient) {}

  getEquipmentPageData(): Observable<PageData> {
    return this.http.get<PageData>(
      '../../assets/json/EquipmentPageData.json'
    );
  }

  getEquipmentList(): Observable<EquipmentList[]> {
    return this.http.get<EquipmentList[]>(
      '../../assets/json/EquipmentList.json'
    );
  }
}
