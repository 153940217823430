import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AccessoryComponent } from './accessory/accessory.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { ContactComponent } from './contact/contact.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { FormComponent } from './form/form.component';

const routes: Routes = [
  { path: 'controle-de-ponto', component: HomeComponent },
  { path: 'acessorios', component: AccessoryComponent },
  { path: 'equipamentos', component: EquipmentComponent },
  { path: 'contato', component: ContactComponent },
  { path: 'produto', component: ProductDetailsComponent },
  { path: 'formulario', component: FormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
