<div class="title-section colored-title">
  <div class="title">{{pageData?.title}}</div>
  <div class="subtitle">{{pageData?.description}}</div>
</div>

<div class="products-content" *ngFor="let accessorys of accessoryList; let index = index"
  [class.accessory-background-list]="showSecondaryBackground(index)">
  <div class="title-component">{{accessorys?.title}}</div>

  <div class="accessory-list" *ngFor="let product of accessorys?.products">
    <div class="accessory-item">
      <app-products [product]="product"></app-products>
    </div>
  </div>
</div>

<div class="skeleton-title skeleton">{{pageData?.title}}</div>
<div class="skeleton-grid">
  <div class="skeleton-img skeleton">{{pageData?.title}}</div>

  <div class="text-skeleton">
    <div class="skeleton-subtitle skeleton">{{pageData?.title}}</div>
    <div class="skeleton-text skeleton">{{pageData?.title}}</div>
  </div>
</div>




<app-footer></app-footer>
