<div class="form-page">

  <div class="contact-column">
    <div class="title"><span class="material-icons">call</span>Contato:</div>
    <div class="text">(12) 3632-6219</div>
    <div class="title"><span class="material-icons">email</span>E-mail:</div>
    <div class="text">contato@rodvale.com.br</div>
    <div class="title"><span class="material-icons">place</span>Endereço:</div>
    <div class="text">
      Rua José Teófilo da Cruz, 805 - Parque Jaraguá<br>
      Taubaté / São Paulo | CEP: 12062-640.
    </div>

    <div class="maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14691.491623964846!2d-45.5343774!3d-22.9917006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe2ea4e43d6aedae!2sRodvale%20-%20Controle%20de%20Ponto%2C%20Acesso%20e%20Assist%C3%AAncia%20T%C3%A9cnica%20HENRY!5e0!3m2!1spt-BR!2sbr!4v1599165293579!5m2!1spt-BR!2sbr"
        width="100%" height="295" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
        tabindex="0"></iframe>
    </div>
  </div>

  <div class="form-column">
    <div class="title">Solicite um orçamento através do nosso WhatsApp</div>
    <!-- <form class="form">
      <mat-form-field class="full-width">
        <mat-label>Nome Completo:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Razão Social da Empresa:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>E-mail:</mat-label>
        <input matInput placeholder="Ex. contato@gmail.com">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Telefone:</mat-label>
        <span matPrefix>+55 &nbsp;</span>
        <input type="tel" matInput placeholder="(12) 3632-6219">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Mensagem:</mat-label>
        <textarea matInput></textarea>
      </mat-form-field>

      <button mat-raised-button>Enviar</button>
    </form> -->
  </div>
  <!-- <div class="form-column">
    <div class="title">Solicite um orçamento através do formulário abaixo</div>
    <form class="form">
      <mat-form-field class="full-width">
        <mat-label>Nome Completo:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Razão Social da Empresa:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>E-mail:</mat-label>
        <input matInput placeholder="Ex. contato@gmail.com">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Telefone:</mat-label>
        <span matPrefix>+55 &nbsp;</span>
        <input type="tel" matInput placeholder="(12) 3632-6219">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Mensagem:</mat-label>
        <textarea matInput></textarea>
      </mat-form-field>

      <button mat-raised-button>Enviar</button>
    </form>
  </div> -->

</div>

<app-footer></app-footer>
