<div class="product">
    <div class="content">
      <div class="img">
        <img [src]="product?.img">
      </div>
      <div class="product-text-column">
        <div class="subtitle">{{product?.name}}</div>
        <div class="text">
          <p *ngFor="let detail of product?.detail">{{detail}}</p>
        </div>
      </div>
    </div>
</div>
