import { Component, OnInit } from '@angular/core';

import { PageData } from '../../interface/page-data.interface';
import { EquipmentService } from '../../services/equipment.service';
import { EquipmentList } from '../../interface/equipment-list.interface';
import { Product } from 'src/app/interface/product.interface';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnInit {
  public pageData: PageData;
  public equipmentList: EquipmentList[];

  constructor(
    private equipmentService: EquipmentService,
    private productService: ProductService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getEquipmentPageData();
    this.getEquipmentList();
  }

  public openProduct(product: Product) {
    this.productService.selectedProduct = product;
    this.router.navigate(['/produto'])
  }

  private getEquipmentPageData(): void {
    this.equipmentService
      .getEquipmentPageData()
      .subscribe((pageData: PageData) => (this.pageData = pageData));
  }

  private getEquipmentList(): void {
    this.equipmentService
      .getEquipmentList()
      .subscribe(
        (equipmentList: EquipmentList[]) => (this.equipmentList = equipmentList)
      );
  }

  showSecondaryBackground(index: number): boolean {
    return index % 2 !== 0;
  }
}
